<template>
  <LayerPopup :popOpen="params.open" :fixedBtn="true" :fullpopup="true" @popClose="onAgree(false)" class="layer"
    title="시설물 이용 약관">
    <div class="terms-container">
      <ReservationLegal></ReservationLegal>
    </div>
    <div class="btn_group">
      <button type="button" class="btn btn-ghost" @click.prevent="onAgree(false)">미동의</button>
      <button type="button" class="btn btn-basic" @click.prevent="onAgree(true)">동의</button>
    </div>
  </LayerPopup>
</template>

<script>
export default {
  id: 'FacilityAgreePopup',
  components: {
    LayerPopup: () => import('@/components/base/LayerPopup.vue'),
    ReservationLegal: () => import('@/views/legal/Reservation.vue'),
  },
  props: {
    params: {
      type: Object,
      default: () => ({
        open: false,
      }),
    },
  },
  data() {
    return {
    };
  },
  methods: {
    onAgree(state) {
      this.params.open = false;
      this.$emit('facilityAgree', state);
    },
  },
};
</script>
